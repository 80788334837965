exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/Links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/Main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

